import React, { useState } from "react";
import './DropdDown1.scss';
import DropdDown2 from "../DropDown2";
import { FaChevronDown } from "react-icons/fa";
import th from "../../images/icons/icon_th.png";
import sg from "../../images/icons/icon_sg.png";
import ma from "../../images/icons/icon_ma.png";

const DropdDown1 = ({ changeHoverState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  const thItems = [
    {
      url: 'https://www.firstpridethailand.com',
      name: 'plant-based'
    }
  ];

  const sgItems = [
    {
      url: 'https://www.firstpride.sg/',
      name: 'plant-based'
    }
  ];

  const myItems = [
    {
      url: 'https://poultry.firstpride.my/',
      name: 'poultry'
    }
  ];

  return (
    <>
      <div className="DropDownContainer">
        <button onClick={toggling} className={`DropDownHeader ${isOpen ? 'show': ''}`}>
          <div className="textt">
            Please Select Your Country <FaChevronDown className="downArrow" />
          </div>
        </button>

        {isOpen && (
          <div>
            <div className="DropDownList">
              <DropdDown2 items={thItems} img={th} title={'THAILAND'} changeHoverState={changeHoverState}/>
              <DropdDown2 items={sgItems} img={sg} title={'SINGAPORE'} changeHoverState={changeHoverState}/>
              <DropdDown2 items={myItems} img={ma} title={'MALAYSIA'} changeHoverState={changeHoverState}/>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DropdDown1;

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../scss/global.scss";
import DropdDown1 from "../components/Dropdown1";
import Helmet from "react-helmet";
import LogoLoop from "../components/LogoLoop";

const IndexPage = () => {
  const [isHover, setIsHover] = useState({hover: false});

  const changeHoverState = (data) => setIsHover(data);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`First Pride`}
      />
      <div className="landingPage">
        <div className="landingContent">
          <LogoLoop isHover={isHover}/>

          <DropdDown1 changeHoverState={changeHoverState}/>
        </div>
      </div>
    </>
  );
};

export default IndexPage;

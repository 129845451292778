import React, { useState } from 'react';
import './DropDown2.scss';
import pb from '../../images/FP-MY-PlantBased.svg';
import pl from '../../images/FP-MY-Poultry.svg';

const DropdDown2 = ({ title, img , items, changeHoverState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  return (
    <div onClick={toggling} className={`ListItem ${isOpen ? 'show': ''}`}>
      <div className="cList">
        <div className="cImg">
          <img src={img} alt={title} />
        </div>

        <div className="dropDownTitle">{title}</div>
      </div>

      {isOpen && (
        <div className="DropDownList">
          {
            items.map((item, i) => (
              <a key={item.name} href={item.url}>
                <div 
                  className={`cList ${i > 0 ? 'second' : 'first'}`} 
                  onMouseOver={() => {changeHoverState({hover: true, flag: item.name == 'plant-based' ? true : false})}}
                  onMouseLeave={() => changeHoverState({hover: false})}>
                  <div className="cImg">
                    <img src={item.name == 'plant-based' ? pb : pl} alt={item.name} />
                  </div>
    
                  <div className="cName">{item.name}</div>
                </div>
              </a>
            ))
          }
        </div>
      )}
    </div>
  );
}

export default DropdDown2;

import React, { useState, useEffect } from 'react';
import './LogoLoop.scss';

const LogoLoop = ({ isHover }) => {
  const [isFirstImage, setIsFirstImage] = useState(true);

  useEffect(() => {
    if (isHover.hover) {
      setIsFirstImage(isHover.flag);
    } else {
      const imgInterval = setTimeout(() => {
        setIsFirstImage(!isFirstImage);
      }, 4000);
    }
  }, [isHover.hover, isFirstImage]);

    return (
      <div>
        <div className="logo-section">
          <h2>WELCOME TO</h2>

          <div className={`logo-img ${isFirstImage ? 'plant-based' : 'poultry'}`}></div>
        </div>
      </div>
    );
}

export default LogoLoop;
